import styled from "styled-components";
import Button from "@material-ui/core/Button";
import spacing from "@material-ui/system/spacing";
import sizing from "@material-ui/system/sizing";

export default styled(Button)`
  && {
    background: ${props => props.background};
    color: ${props => props.textcolor};
    ${spacing}
    ${sizing}
  }
`;
